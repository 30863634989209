import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// ----------- Pages ------------
import LandingPage from './pages/Landing';
import ListingPage from './pages/Listing';
import SigninPage from './pages/Signin';
import SignupPage from './pages/Signup';
import ForgotPasswordPage from './pages/ForgotPassword';
import ListingsPage from './pages/ListingsPage';
import ChatRoomPage from './pages/ChatRoom';
import NewsDetailPage from './pages/NewsDetail';
import SuccessPage from './pages/Success';
import CategoriesPage from './pages/Categories';
import NewsPage from './pages/News';
import NewListingPage from './pages/NewListing';
import ProfilePage from './pages/Profile';

// ----------- Layout Components -----------

interface Props {}

export default function Routes({}: Props) {
  return (
    <Router>
      <Route exact path='/' component={ListingsPage} />
      <Route exact path='/create/listing' component={NewListingPage} />
      <Route exact path='/signin' component={SigninPage} />
      <Route exact path='/signup' component={SignupPage} />
      <Route exact path='/forgot' component={ForgotPasswordPage} />
      <Route exact path='/chat' component={ChatRoomPage} />
      <Route exact path='/listings' component={ListingsPage} />
      <Route exact path='/news' component={NewsPage} />
      <Route exact path='/news/:id' component={NewsDetailPage} />
      <Route exact path='/listing/:id' component={ListingPage} />
      <Route exact path='/success' component={SuccessPage} />
      <Route exact path='/ads/:id' component={SuccessPage} />
      <Route exact path='/profile/:userId' component={ProfilePage} />
    </Router>
  );
}

{
  /* // <Route exact path='/pricing' component={PricingPage} />
        // <Route exact path='/catalog' component={Catalog} />
        // <Route exact path='/list' component={Listings} />
        // <Route exact path='/search' component={Listings} />
        // <Route exact path='/landing/miniapp' component={MiniAppLanding} />
        // <Route exact path='/new/listing' component={NewListingPage} /> */
}

import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AdsCol from '../../components/AdsCol';
import Layout from '../../components/Layout';
import News from '../../services/news';
import { INewsDetails, NEWS_TITLE_MAP } from '../../types/News';
import ChevronLeft from '../../assets/icons/Chevron Left.svg';

import './index.scss';
import '../../styles/details-container.scss';
import Reaction from '../../components/Reaction';
import Comments from '../../components/Comments';

interface Props {}

export default function NewsDetailPage({}: Props): ReactElement {
  const [data, setData] = React.useState<INewsDetails | null>(null);
  const location = useLocation();
  const history = useHistory();
  const newsId = location.pathname.split('/')[2];
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await News.getNewsDetail(newsId, 'html');

      setData(response.data.data);
    };
    fetchData();
  }, []);
  const handleLike = () => News.likeNews(newsId);
  const handleShare = () => News.shareNews(newsId);

  return (
    <Layout
      leftCol={
        <div className='details-container news-detail-container'>
          <div className='header'>
            <div className='title'>
              <img
                onClick={() => history.goBack()}
                src={ChevronLeft}
                alt='Chevron-left'
              />
              <h1>{data?.htmlContent.title}</h1>
            </div>
            <div className='sub-title'>
              {data && <span>{NEWS_TITLE_MAP[data?.category]}</span>}
              <span>{data?.originalDate}</span>
            </div>
          </div>
          {data && data.htmlContent && (
            <div
              className='news-content'
              dangerouslySetInnerHTML={{ __html: data?.htmlContent.body }}
            ></div>
          )}
          <Reaction
            likes={data ? data.likes : 0}
            shares={data ? data.shares : 0}
            onLike={handleLike}
            onShare={handleShare}
          />
          {/* <Comments id={newsId} /> */}
        </div>
      }
      showSidebar
    />
  );
}

import React, { ReactElement } from 'react';
import Button from '../../components/Button';
import { Link, useHistory } from 'react-router-dom';
import Auth from '../../services/auth';
import { GoogleLogin } from 'react-google-login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../../services/users';
import GoogleSvg from '../../assets/social/google.svg';
import FacebookSvg from '../../assets/social/facebook.svg';
import InstagramSvg from '../../assets/social/ins.svg';
import TwitterSvg from '../../assets/social/twitter.svg';
import LinkedinSvg from '../../assets/social/linkedin.svg';
import { globalUserInfo, globalAuthToken } from '../../states';

interface Props {}
export default function SigninForm({}: Props): ReactElement {
  const history = useHistory();
  const googleKey = process.env.REACT_APP_GOOGLE_OAUTH || '';

  const [phone, setPhone] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleGoogle = async (response: any) => {
    const result = await Auth.responseGoogle(response);
    if (result.data.success) {
      globalAuthToken.set(result.data.token);
      const userInfoResult = await UserService.fetchUserByToken(
        result.data.token
      );
      globalUserInfo.set(userInfoResult.data.data);
      toast.success('登录成功');
      setTimeout(() => {
        history.push('/');
      }, 2000);
    } else {
      toast.error('登录失败');
    }
  };

  const handleLogin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (phone === '' || password === '') {
      toast.warn('请填写所有信息');
      return false;
    }
    const result = await Auth.handleLogin(phone, password);
    if (result.data.success) {
      globalAuthToken.set(result.data.token);
      const userInfoResult = await UserService.fetchUserByToken(
        result.data.token
      );
      globalUserInfo.set(userInfoResult.data.data);
      toast.success('登录成功', { autoClose: 1500 });
      setTimeout(() => {
        history.push('/');
      }, 2000);
    } else {
      toast.error('登录失败');
    }
  };
  const loginGoogleFail = () => {
    toast.warn('Google登录已取消');
  };

  return (
    <form>
      <h1>登录</h1>
      <ToastContainer />
      <div className='input-container'>
        <label htmlFor='phone'>手机号</label>
        <input
          id='phone'
          type='text'
          placeholder='请输入您的手机号'
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className='input-container'>
        <label htmlFor='password'>密码</label>
        <input
          type='password'
          id='password'
          placeholder='输入密码'
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to='/forgot'>忘记密码?</Link>
      </div>
      <Button type='primary' block onClick={handleLogin}>
        登录
      </Button>
      <hr />
      <GoogleLogin
        clientId={googleKey}
        buttonText='使用Google登录'
        onSuccess={handleGoogle}
        onFailure={loginGoogleFail}
        cookiePolicy={'single_host_origin'}
      />
      {/* <h2>使用社交账号登录</h2>
      <ul className='social-media-container'>
        <li>
          <img src={GoogleSvg} alt='google' />
        </li>
        <li>
          <img src={FacebookSvg} alt='facebook' />
        </li>
        <li>
          <img src={InstagramSvg} alt='instagram' />
        </li>
        <li>
          <img src={TwitterSvg} alt='twitter' />
        </li>
        <li>
          <img src={LinkedinSvg} alt='linkedin' />
        </li>
      </ul> */}
      <hr />
      <span>
        没有账号? 一键 <Link to='/signup'>注册</Link>
      </span>
    </form>
  );
}

import React from 'react';
import Routes from './Routes';
import { globalUserInfo, persistenceKeyMap, globalAuthToken } from './states';
import './styles/index.css';
import './styles/normalize.scss';
import './styles/global.scss';
import UserService from './services/users';
import { Persistence } from '@hookstate/persistence';
import { useState } from '@hookstate/core';
import Alert from './components/Alert';
function App() {
  const userInfo = useState(globalUserInfo);
  const authToken = useState(globalAuthToken);
  userInfo.attach(Persistence(persistenceKeyMap.userInfo));
  authToken.attach(Persistence(persistenceKeyMap.authToken));
  return (
    <div className='app-container'>
      <Routes />
      <Alert />
    </div>
  );
}

export default App;

import { useState } from '@hookstate/core';
import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import Categories from '../../services/categories';
import { globalCategoryState } from '../../states';
import { Category, ECategory, SubCategory } from '../../types/Listing';
import { ENewsType, NEWS_TITLE_MAP, TNewsMapEntries } from '../../types/News';
import { parse } from '../../utils';
import SearchBar from '../SearchBar';
import './index.scss';
import MenuItem from './MenuItem';

interface Props {}

export default function Sidebar({}: Props): ReactElement {
  const categoryState = useState(globalCategoryState);
  const newsCategories: SubCategory[] = React.useMemo(() => {
    const obj: TNewsMapEntries[] = Object.entries(NEWS_TITLE_MAP);
    const value = obj.map(([key, value]) => {
      return {
        subCategory: key,
        fullTitle: value,
        icon: key + '.png',
        shortTitle: value,
        category: ECategory.NEWS,
      };
    });

    return value;
  }, [NEWS_TITLE_MAP]);

  return (
    <div className='sidebar-container'>
      {/* <div className='sidebar-header'>
        <h2>全部板块</h2>
        <SearchBar placeholder='搜索板块' />
      </div> */}
      <div className='sidebar-menu'>
        <MenuItem categories={newsCategories} title='今日头条' />
        {categoryState.value &&
          categoryState.value.map((v) => {
            return (
              <MenuItem
                key={v.title}
                title={v.title}
                categories={v.categories}
              />
            );
          })}
      </div>
    </div>
  );
}

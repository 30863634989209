import axios, { AxiosResponse } from 'axios';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const createSubscription = (email: string): Promise<AxiosResponse> => {
    return axios.post(apiUrl + '/subscriptionEmails/subscribe', {
        email,
    });
};

const getSubscriptionByEmail = (email: string): Promise<AxiosResponse> => {
    return axios.get(apiUrl + '/subscriptionEmails', {
        params: {
            email,
        }
    });
};

const SubscriptionService = {
    createSubscription,
    getSubscriptionByEmail,
};

export default SubscriptionService;
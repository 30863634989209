import React, { ReactElement, useState, useEffect } from 'react';
import AdsCol from '../../components/AdsCol';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import './index.scss';
import ListingService from '../../services/listing';
import { ECategory, ESubCategory, IListing } from '../../types/Listing';
import ListingItem from '../../components/ListingItem';
import { formatThousands } from '../../utils';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import ScrollToTop from '../../components/ScrollTop';
import Skeleton from 'react-loading-skeleton';

import './index.scss';
import '../../styles/details-container.scss';

interface Props {}

export default function ListingsPage({}: Props): ReactElement {
  const [listings, setListings] = useState<IListing[]>([]);
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [stats, setStats] = useState<null | { total: number; pages: number }>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  const [subCategory, setSubCategory] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const fetchData = () => {
    setLoading(true);
    setCurrentPage(1);
    if (category && subCategory) {
      ListingService.fetchListingsByCategory(category, subCategory, 1)
        .then(async (response) => {
          const sticky = await ListingService.fetchStickyListingByCategory(
            category,
            subCategory
          );
          const nextListings = [...sticky.data.data, ...response.data.data];

          setListings(nextListings);

          setStats({
            total: response.data.totalListingCount,
            pages: response.data.totalPageCount,
          });
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const loadAndMergeListings = async () => {
    let nextPageListings;
    const page = currentPage;
    if (category && subCategory && window.scrollY > 200) {
      if (search) {
        nextPageListings = await ListingService.searchListings(
          search,
          page + 1
        );
      } else {
        nextPageListings = await ListingService.fetchListingsByCategory(
          category,
          subCategory,
          page + 1
        );
      }

      const combinedListings = [...listings, ...nextPageListings.data.data];
      setListings(combinedListings);
      setStats({
        total: nextPageListings.data.totalListingCount,
        pages: nextPageListings.data.totalPageCount,
      });
    }
  };

  const changePage = () => {
    if (canLoadMore) {
      setCanLoadMore(false);
      setCurrentPage(currentPage + 1);

      setTimeout(() => {
        setCanLoadMore(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      loadAndMergeListings();
    }
  }, [currentPage]);

  const syncQueryToState = () => {
    const query = querystring.parse(location.search);
    const { category = 'rental', subCategory = '皇后区', search } = query;
    if (category && !Array.isArray(category)) {
      setCategory(category);
    }
    if (subCategory && !Array.isArray(subCategory)) {
      setSubCategory(subCategory);
    }
    if (search && !Array.isArray(search)) {
      setSearch(search);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    syncQueryToState();
  }, [location]);

  useEffect(() => {
    if (category && subCategory) {
      fetchData();
    }
  }, [category, subCategory]);

  return (
    <>
      <ScrollToTop />
      <Layout
        showSidebar
        leftCol={
          <div className='listing-left-col'>
            <Button
              block
              type='tertiary'
              size='huge'
              className='listing-post-button'
              onClick={() => history.push('/create/listing')}
            >
              <EditIcon />
              发表帖子
            </Button>
            {stats && !loading && (
              <span className='stats'>
                在{(Math.random() * 10).toFixed(2)}毫秒内找到
                {formatThousands(stats.total)}
                条结果，共
                {formatThousands(stats.pages)}页
                <span className='link' onClick={fetchData}>
                  刷新
                </span>
              </span>
            )}
            <InfiniteScroll
              pageStart={0}
              loadMore={changePage}
              hasMore={stats ? currentPage < stats.pages : false}
              loader={<Skeleton count={1} height={300} key='loader' />}
            >
              <div className='listings-container'>
                {loading ? (
                  <SkeletonPlaceholder key='loader' />
                ) : (
                  listings.map((v, i) => (
                    <ListingItem index={i} key={i} data={v} />
                  ))
                )}
              </div>
            </InfiniteScroll>
          </div>
        }
      />
    </>
  );
}

import React, { ReactElement } from 'react';
import classnames from 'classnames';
import './Hamburger.scss';
import Badge from '../Badge';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {
  ChartBarIcon,
  UserCircleIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import ChevronRight from '../../assets/icons/Chevron Right.svg';
import ChevronDown from '../../assets/icons/Chevron Down.svg';
import ChevronUp from '../../assets/icons/Chevron Up.svg';
import SearchBar from '../SearchBar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CategoryMenu from './CategoryMenu';
import { globalUserInfo, globalAuthToken } from '../../states';
import avatarPlaceholder from '../../assets/illustration/avatarPlaceholder.png';
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
interface Props {
  openDropdown: boolean;
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Dropdown({
  openDropdown,
  setDropdown,
}: Props): ReactElement {
  const history = useHistory();
  const user = globalUserInfo.value;
  const options = [
    {
      name: '我的贴子',
      href: `/profile/${user?.id}`,
      icon: UserCircleIcon,
    },
  ];
  const handleLogout = () => {
    globalAuthToken.set(null);
    globalUserInfo.set(null);
    history.push('/');
  };
  return (
    <Popover className='relative inline-block'>
      {(openDropdown) => (
        <>
          <Popover.Button
            className={classNames(
              openDropdown ? 'text-gray-900' : 'text-gray-500',
              'bg-white rounded-md items-center text-base font-medium hover:text-gray-900 focus:outline-none'
            )}
          >
            <img
              height='40'
              width='40'
              className='rounded-full inline-flex font-medium focus:outline-none'
              src={user?.avatar || avatarPlaceholder}
              alt='avatar'
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute z-10 right-0 transform mt-3 sm:px-0'>
              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                <div className='relative grid gap-6 bg-white px-1 py-2 sm:gap-8 sm:p-6 px-5 py-5'>
                  {options.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className='-m-3 p-3 flex cursor-pointer items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150'
                    >
                      <item.icon
                        className='flex-shrink-0 h-6 w-6 text-gray-400'
                        aria-hidden='true'
                      />
                      <div className='ml-4'>
                        <p className='text-base text-gray-900 break-normal inline-block whitespace-nowrap'>
                          {item.name}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                <div className='px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 w-full'>
                  <div className='flow-root'>
                    <a
                      onClick={() => {
                        handleLogout();
                      }}
                      className='-m-3 p-3 flex cursor-pointer items-center rounded-md text-basetext-gray-900 hover:bg-gray-100 transition ease-in-out duration-150'
                    >
                      <LogoutIcon
                        className='flex-shrink-0 h-6 w-6 text-red-400'
                        aria-hidden='true'
                      />
                      <span className='ml-4 text-base text-gray-900 break-normal inline-block whitespace-nowrap'>
                        退出登录
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

import React, { useEffect, Fragment, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  useElements,
  Elements,
  useStripe,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import PaymentService from '../../services/payment';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { useState } from '@hookstate/core';
import { PostForm, StickyPricing } from '../../pages/NewListing';
import { toast, ToastContainer } from 'react-toastify';
import ListingService from '../../services/listing';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import classNames from 'classnames';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

interface Props extends PaymentFormProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PaymentFormProps {
  pricing: StickyPricing;
  data: PostForm;
  cancelButtonRef?: React.MutableRefObject<any>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentPopup: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  pricing,
  data,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={setIsOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <Elements stripe={stripePromise}>
                <PaymentForm
                  pricing={pricing}
                  data={data}
                  setIsOpen={setIsOpen}
                  cancelButtonRef={cancelButtonRef}
                />
              </Elements>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
  pricing,
  data,
  setIsOpen,
  cancelButtonRef,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [wechatID, setWechatID] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const history = useHistory();

  const days = Number(pricing.key.split('day')[0]);
  const now = new Date().getTime();
  const duration = (days + 1) * 24 * 60 * 60 * 1000;
  const expiration = now + duration;

  const expirationDate = dayjs(expiration).format('YYYY/MM/DD HH:mm A');

  const promptText = `您现在正在支付的是消息通置顶广告，将在${data.category}类别、${data.subCategory}分类置顶${pricing.title}。置顶费用为：$${pricing.price}，置顶期为${pricing.title}。五分钟内完成付款即可获赠额外24小时置顶时间，置顶至${expirationDate}。完成支付后您会收到一封支付凭证邮件，如果对置顶广告有任何问题可以致电我们: (646)639-7087或加微信号：peter_4669。`;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!email) {
      toast.error('请填写您的电子邮箱以接收付款确认邮件');
      setIsSubmitting(false);
      return false;
    }
    if (!stripe || !elements) {
      setIsSubmitting(false);
      return false;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setIsSubmitting(false);
      return false;
    }
    try {
      const clientSecretData = await PaymentService.getClientSecret(
        pricing.price,
        email,
      );
      const result = await stripe.confirmCardPayment(
        clientSecretData.data.data,
        {
          payment_method: {
            card: cardElement,
          },
        },
      );
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        toast.success('发布成功！将在五秒内转页。');
        const id = await ListingService.postListing({
          ...data,
          isSticky: expiration,
        });
        setSuccess(true);
        setTimeout(async () => {
          try {
            history.push(`/listing/${id.data.data}`);
          } catch (error) {
            toast.error('好像出了点问题，请稍后再试');
          }
        }, 5000);
      } else {
        alert('支付失败！');
        setIsSubmitting(false);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disabled = isSuccess || isSubmitting;

  return (
    <form>
      <div className='grid grid-flow-row auto-rows-max justify-items-stretch'>
        <h3 className='content-center text-lg leading-6 font-medium text-gray-900 justify-self-center'>
          支付信息
        </h3>
        <div className='mt-2 max-w-xl text-sm text-gray-500'>
          <p className='mb-2'>{promptText}</p>
        </div>
        <div className='my-3'>
          <div className='flex justify-between'>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-700'
            >
              邮箱地址
            </label>
            <span className='text-sm text-gray-500' id='email-optional'>
              必填
            </span>
          </div>
          <div className='mt-1'>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              name='email'
              id='email'
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
              placeholder='Email Address'
              aria-describedby='email-optional'
            />
          </div>
        </div>
        <CardElement className='pt-5 pb-5' />
        <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <button
            type='button'
            className={classNames(
              'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
              {
                'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 cursor-not-allowed':
                  disabled,
                'bg-green-600 hover:bg-green-700 focus:ring-green-500':
                  !disabled,
              },
            )}
            onClick={handleSubmit}
            disabled={disabled}
          >
            {isSuccess
              ? '支付成功 🎉'
              : isSubmitting
              ? '支付中...'
              : `支付 $${pricing.price}`}
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm'
            onClick={() => setIsOpen(false)}
            ref={cancelButtonRef}
          >
            取消
          </button>
        </div>
      </div>
    </form>
  );
};

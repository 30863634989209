import React, { ReactElement, ReactNode } from 'react';
import './index.scss';

interface Props {
  children: ReactNode;
  color?: string;
}

export default function Badge({
  children,
  color = '#1aad19',
}: Props): ReactElement {
  return (
    <span style={{ backgroundColor: color }} className='badge'>
      {children}
    </span>
  );
}

import axios, { AxiosResponse } from 'axios';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const fetchUserById = (id: string): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/users/get/userInfo', {
    params: {
      id,
    },
  });
};

const fetchUserByToken = (token: string): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/users/get/userInfo/token', {
    params: {
      token,
    },
  });
};

const getAccessToken = (
  appId: string,
  appSecret: string,
  code: string
): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/users/post/wx/web/token', {
    code,
    appSecret,
    appId,
  });
};

const getWxUserInfo = (
  token: string,
  openid: string
): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/users/post/wx/web/userInfo', {
    token,
    openid,
  });
};

const UserService = {
  fetchUserById,
  fetchUserByToken,
  getWxUserInfo,
  getAccessToken,
};

export default UserService;

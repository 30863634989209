import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

interface Props {}

export default function ForgotPasswordForm({}: Props): ReactElement {
  return (
    <form>
      <h1>忘记密码？</h1>
      <span>验证您的电话号码，然后设置一个新密码；或直接</span>
      <Link to='/signin'>登录</Link>
      <div className='input-container'>
        <label htmlFor='phone'>手机号</label>
        <input id='phone' type='text' placeholder='请输入您的手机号' />
        <div className='verification-code-container'>
          <input type='text' placeholder='输入验证码' />
          <Button type='secondary'>发送验证码</Button>
        </div>
      </div>
      <div className='input-container'>
        <label htmlFor='password'>新密码</label>
        <input type='password' id='password' placeholder='输入密码' />
      </div>
      <Button type='primary' block>
        修改密码
      </Button>
    </form>
  );
}

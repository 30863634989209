import React, { ReactElement } from 'react';
import Layout from '../../components/Layout';
import { useHistory, useLocation } from 'react-router';
import { CategoryMap, IListing } from '../../types/Listing';
import ListingService from '../../services/listing';
import Reaction from '../../components/Reaction';
import Comments from '../../components/Comments';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import ImageGallery from 'react-image-gallery';

import './index.scss';
import '../../styles/details-container.scss';

interface Props {}

export interface IListingDetail {
  htmlContent: {
    title: string;
    body: string;
    images: string[];
    phone: string;
  };
  fromNow: string;
  _id: string;
  isFavorite: boolean;
  isSticky: boolean;
  isReviewed: boolean;
  category: string;
  subCategory: string;
  views: number;
  likes: number;
  shares: number;
  author: string;
}

const ListingPage: React.FC<Props> = (props) => {
  const location = useLocation();
  const [data, setData] = React.useState<IListingDetail | null>(null);
  const listingId = location.pathname.split('/')[2];

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await ListingService.fetchListingById(listingId);

      setData(response.data.data);
    };
    fetchData();
  }, []);
  const handleLike = () => ListingService.handleListingLike(listingId);
  // const handleShare = () => ListingService.handle(newsId);

  return (
    <Layout
      leftCol={
        <>
          {data ? (
            <div className='details-container listing-detail-container'>
              <div className='header'>
                <div className='title'>
                  <h1>{data.htmlContent.title}</h1>
                </div>
                <div className='sub-title'>
                  <span>
                    {data.subCategory} {CategoryMap[data.category]}
                  </span>
                  <span>{data.author || '游客'}</span>
                  <span>发布于{data.fromNow}</span>
                </div>
              </div>
              {data.htmlContent.images.length ? (
                <div className='px-8'>
                  <ImageGallery
                    items={data.htmlContent.images.map((v) => ({
                      original: v,
                      originalHeight: 300,
                    }))}
                  />
                </div>
              ) : null}
              <div
                className='news-content mt-4'
                dangerouslySetInnerHTML={{ __html: data.htmlContent.body }}
              ></div>
              {/* <Reaction
                likes={data ? data.likes : 0}
                shares={data ? data.shares : 0}
                onLike={handleLike}
                onShare={handleLike}
              /> */}
              {/* <Comments id={listingId} /> */}
            </div>
          ) : (
            <SkeletonPlaceholder />
          )}
        </>
      }
      showSidebar
    />
  );
};

export default ListingPage;

/* This example requires Tailwind CSS v2.0+ */
import React, {
  Fragment,
  useRef,
  useState,
  ReactElement,
  ChangeEvent,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Button from '../../components/Button';
import { none, useHookstate } from '@hookstate/core';
import { IListing } from '../../types/Listing';
import { toast } from 'react-toastify';
import { parse } from '../../utils';
import ListingService from '../../services/listing';
interface Props {
  openEdit: boolean;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: IListing | null;
  setUpdatedData: React.Dispatch<React.SetStateAction<IListing | null>>;
}
interface PostForm {
  id: string;
  title: string;
  body: string;
  phone: string;
  images: string[];
}
export default function editModal({
  openEdit,
  setEditOpen,
  data,
  setUpdatedData,
}: Props): ReactElement {
  const cancelButtonRef = useRef(null);
  const formState = useHookstate<PostForm>({
    id: data?._id || '',
    title: data?.title || '',
    body: data?.body || '',
    phone: data?.phone || '',
    images: data?.images || [],
  });
  const [newImages, setNewImages] = React.useState<any>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);
  React.useEffect(() => {
    setSubmitting(false);
  }, [openEdit]);
  React.useEffect(() => {
    formState.id.set(data?._id || '');
    formState.title.set(data?.title || '');
    formState.body.set(data?.body || '');
    formState.phone.set(data?.phone || '');
    formState.images.set(data?.images || []);
  }, [data]);
  const handleValidation = (data: PostForm) => {
    if (!data.title) {
      toast.error('请输入标题');
      return false;
    }
    if (!data.body) {
      toast.error('请输入正文');

      return false;
    }
    if (data.phone) {
      if (!(/^\d+$/.test(data.phone) && data.phone.length === 10)) {
        toast.error('手机号格式不正确');
        return false;
      }
    }
    return true;
  };
  const checkImgNumber = () => {
    const nemImageNumber = newImages ? newImages.length : 0;
    const oldImageNumber = formState.value.images
      ? formState.value.images.length
      : 0;
    if (nemImageNumber + oldImageNumber >= 9) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddNewImg = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.files) {
      const imgList = newImages || [];
      for (let i = 0; i < e.target.files.length; i++) {
        if (i <= 8) {
          imgList.push(e.target.files[i]);
        } else {
          i = e.target.files.length;
        }
      }
      setNewImages(imgList);
    }
  };
  const removeNewImage = (index: number) => {
    const imgList = newImages;
    imgList.splice(index, 1);
    setNewImages([...imgList]);
  };
  const removeOldImage = (index: number) => {
    const imgList: string[] = [];
    formState.images.value.forEach((v, i) => {
      if (!(i === index)) {
        imgList.push(v);
      }
    });
    formState.images.set(imgList);
  };
  const handleEdit = async () => {
    setSubmitting(true);
    const updateData: PostForm = parse(formState.value);
    if (handleValidation(updateData)) {
      if (newImages && newImages.length) {
        const response = await ListingService.uploadImages(newImages);
        const oldImageArr = formState.images.value;
        const newArr = oldImageArr.concat(response.data.data);
        formState.images.set(newArr);
        updateData.images = newArr;
      }
      try {
        const updated = await ListingService.updatePost(updateData);
        if (updated.data.data && data) {
          setUpdatedData({
            ...data,
            title: updateData.title,
            body: updateData.body,
            phone: updateData.phone,
            images: updateData.images,
          });
          setNewImages(null);
          setSubmitting(false);
          toast.success('修改成功！');
          setEditOpen(false);
        } else {
          throw '修改失败';
        }
      } catch (error) {
        setSubmitting(false);
        toast.error('好像出了点问题，请稍后再试');
      }
    } else {
      setSubmitting(false);
    }
  };
  return (
    <Transition.Root show={openEdit} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={setEditOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start flex-col w-full'>
                  <div className='input-container w-full'>
                    <label>标题*</label>
                    <input
                      className='round-md mt-1'
                      value={formState.value.title}
                      onChange={(e) => formState.title.set(e.target.value)}
                      type='text'
                    />
                  </div>
                  <div className='input-container w-full'>
                    <label>正文*</label>
                    <textarea
                      className='round-md mt-1'
                      value={formState.value.body}
                      onChange={(e) => formState.body.set(e.target.value)}
                      cols={30}
                      rows={10}
                    />
                  </div>
                  <div className='input-container w-full'>
                    <label>联系电话(请不要输入任何标点符号)</label>
                    <input
                      type='tel'
                      value={formState.value.phone}
                      onChange={(e) => formState.phone.set(e.target.value)}
                    />
                  </div>
                  <div className='input-container w-full'>
                    <label>图片</label>
                    <div className='preview flex'>
                      {formState.value.images.map((v, i) => (
                        <div className='preview-image-container' key={i}>
                          <img
                            src={v}
                            alt='post image'
                            height={60}
                            width={60}
                          />
                          <button onClick={() => removeOldImage(i)}>
                            移除
                          </button>
                        </div>
                      ))}
                      {newImages &&
                        newImages.map((v: any, i: number) => (
                          <div className='preview-image-container' key={i}>
                            <img
                              height={60}
                              width={60}
                              src={URL.createObjectURL(v)}
                              alt='post image'
                            />
                            <button
                              onClick={() => {
                                removeNewImage(i);
                              }}
                            >
                              移除
                            </button>
                          </div>
                        ))}
                    </div>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <div className='max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                        <div className='space-y-1 text-center'>
                          <svg
                            className='mx-auto h-12 w-12 text-gray-400'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 48 48'
                            aria-hidden='true'
                          >
                            <path
                              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          <div className='flex text-sm text-gray-600'>
                            <label
                              htmlFor='file-upload'
                              className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                            >
                              <span>上传文件</span>
                              <input
                                id='file-upload'
                                disabled={checkImgNumber()}
                                multiple
                                onChange={(e) => handleAddNewImg(e)}
                                type='file'
                                className='sr-only'
                              />
                            </label>
                            <p className='pl-1'>或拖拽至框中</p>
                          </div>
                          <p className='text-xs text-gray-500'>
                            最多可上传九张图片
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                <button
                  disabled={isSubmitting}
                  style={{ backgroundColor: '#1AAD19' }}
                  className='mt-3 w-full inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={() => handleEdit()}
                >
                  {isSubmitting ? '保存中' : '保存'}
                </button>
                <button
                  type='button'
                  className='mt-3 w-full inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={() => setEditOpen(false)}
                >
                  取消
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { ReactElement } from 'react';
import classnames from 'classnames';
import './Hamburger.scss';
import Badge from '../Badge';

import ChevronRight from '../../assets/icons/Chevron Right.svg';
import ChevronDown from '../../assets/icons/Chevron Down.svg';
import ChevronUp from '../../assets/icons/Chevron Up.svg';
import SearchBar from '../SearchBar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CategoryMenu from './CategoryMenu';

interface Props {}
interface MenuProps {
  active: boolean;
}

export default function Hamburger({}: Props): ReactElement {
  const [isHamburgerActive, setHamburgerActive] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setHamburgerActive(false);
  }, [location.pathname]);

  return (
    <div className='hamburger-container'>
      <div
        onClick={() => setHamburgerActive(!isHamburgerActive)}
        className={classnames('hamburger', {
          active: isHamburgerActive,
        })}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Menu active={isHamburgerActive} />
    </div>
  );
}

function Menu({ active }: MenuProps): ReactElement {
  const history = useHistory();

  const [isCategoryMenuActive, setCategoryMenuActive] = React.useState(false);
  return (
    <div
      className={classnames('hamburger-menu', {
        active,
      })}
    >
      <SearchBar />
      <ul>
        <li onClick={() => history.push('/')}>
          <span>首页</span>
          <img src={ChevronRight} alt='Chevron-right' />
        </li>
        <li onClick={() => setCategoryMenuActive(!isCategoryMenuActive)}>
          <span>
            分类信息<Badge>99+</Badge>
          </span>
          <img
            src={isCategoryMenuActive ? ChevronDown : ChevronUp}
            alt='Chevron'
          />
        </li>
        <CategoryMenu active={isCategoryMenuActive} />
        <li onClick={() => history.push('/news')}>
          <span>
            今日头条<Badge>99+</Badge>
          </span>

          <img src={ChevronRight} alt='Chevron-right' />
        </li>
        <li>
          <span>
            新冠疫情追踪<Badge>实时</Badge>
          </span>

          <img src={ChevronRight} alt='Chevron-right' />
        </li>
        {/* <li onClick={() => history.push('/miniapp')}>
          <span>微信小程序</span>

          <img src={ChevronRight} alt='Chevron-right' />
        </li> */}
        <li onClick={() => history.push('/ads')}>
          <span>刊登广告</span>

          <img src={ChevronRight} alt='Chevron-right' />
        </li>
        <li onClick={() => history.push('/chat')}>
          <span>
            聊天室<Badge>168人在线</Badge>
          </span>

          <img src={ChevronRight} alt='Chevron-right' />
        </li>
      </ul>
    </div>
  );
}

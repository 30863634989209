import React, { ReactElement } from 'react';
import SplashLayout from '../../components/SplashLayout';
import SignupForm from './SignupForm';

import './index.scss';

interface Props {}

export default function SignupPage({}: Props): ReactElement {
  return <SplashLayout form={<SignupForm />} />;
}

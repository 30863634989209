import React, { ReactElement } from "react";
import Layout from "../../components/Layout";
import { ReactComponent as Checkmark } from "../../assets/icons/Checkmark.svg";
import "./index.scss";

interface Props {}

export default function SuccessPage({}: Props): ReactElement {
  return (
    <Layout>
      <div className="container">
        <div className="check-container">
          <div>
            <Checkmark />
          </div>
        </div>
        <div className="text">
          <h4>广告投放成功!</h4>
        </div>
        <div className="success-button">
          <button>回到首页</button>
        </div>
      </div>
    </Layout>
  );
}

import React, { ReactElement } from 'react';
import { IListing } from '../../types/Listing';
import { ReactComponent as LikeIcon } from '../../assets/icons/Heart.svg';
import { ReactComponent as FireIcon } from '../../assets/icons/Fire.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/Share.svg';
import { ReactComponent as PictureIcon } from '../../assets/icons/Picture.svg';
import './index.scss';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import classNames from 'classnames';

interface Props {
  data: IListing;
  index: number;
}

export default function ListingItem({ data, index }: Props): ReactElement {
  const history = useHistory();
  const location = useLocation();

  const hotness = useMemo(
    () => (data.views + Math.floor(Math.random() * 10)) * 18,
    [location]
  );

  return (
    <div className='listing-item-container'>
      <div className='title-container'>
        <div className='flex items-center'>
          {(data.isSticky || data.isOriginal) && (
            <span
              className={classNames(
                'flex items-center mr-2 bg-blue-600 text-white px-2 rounded-lg text-sm h-6',
                {
                  'bg-green-600': data.isSticky,
                }
              )}
            >
              {data.isSticky ? '置顶' : data.isOriginal && '原创'}
            </span>
          )}
          <Link to={'/listing/' + data._id} target='_blank'>
            <h4>
              {data.title}{' '}
              {data.images && data.images.length ? (
                <>
                  <PictureIcon />
                </>
              ) : null}
            </h4>
          </Link>
        </div>
        <span>#{index + 1}</span>
      </div>
      {data.images && data.images.length ? (
        <div className='images-container'>
          <img src={data.images[0]} alt={data.title} />
          <span>&copy;纽约消息通</span>
        </div>
      ) : null}
      <div className='body'>
        {data.body.length > 100 ? data.body.slice(0, 100) + '...' : data.body}
      </div>
      <div className='footer'>
        <span>{data.fromNow}</span>
        <div className='reaction-list'>
          <div className='reaction'>
            <LikeIcon />
            <span>点赞 {data.likes}</span>
          </div>
          <div className='reaction'>
            <FireIcon />
            <span>热度 {hotness}</span>
          </div>
          <div className='reaction'>
            <ShareIcon />
            <span>分享 {data.likes}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import LogoPng from '../../assets/logo/logo-square.png';

import './index.scss';

interface Props {
  height?: number;
  width?: number;
  withText?: boolean;
}

const DEFAULT_LOGO_SIZE = '40px';

export default function Logo({
  height,
  width,
  withText = false,
}: Props): ReactElement {
  const styles: { height?: string; width?: string } = {};
  if (height) styles.height = `${height}px`;
  if (width) styles.width = `${width}px`;
  return (
    <Link to='/' className='logo-container'>
      <img style={styles} src={LogoPng} alt='消息通' />
      {withText && <span>消息通</span>}
    </Link>
  );
}

import axios, { AxiosResponse } from 'axios';
import { PostForm } from '../pages/NewListing';
import { IListing, ECategory } from '../types/Listing';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const fetchListingById = (
  id: string,
  userId?: string
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/listings/get', {
    params: {
      id,
      userId,
      format: 'html',
    },
  });
};

const handleListingLike = (id: string): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/listings/post/like', {
    id,
  });
};

const fetchStickyListingByCategory = (
  category: string,
  subCategory: string
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/listings/get/list/sticky', {
    params: { category, subCategory },
  });
};

const fetchListingByUserId = (
  id: string,
  page: number
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/listings/get/list/byUser', {
    params: { id, page },
  });
};

const fetchFavListingByUserId = (
  id: string,
  page: number
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/listings/get/list/favorites', {
    params: { id, page },
  });
};

const fetchListingsByCategory = (
  category: string,
  subCategory: string,
  page: number
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/listings/get/list', {
    params: {
      page: page || 1,
      category,
      subCategory,
    },
  });
};

const searchListings = (q: string, page: number): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/listings/post/list/search', {
    search: q,
    page,
  });
};

const uploadImages = (files: any[]): Promise<AxiosResponse> => {
  const fileData = new FormData();
  for (let i = 0; i < files.length; i++) {
    fileData.append('files', files[i]);
  }
  return axios.post(apiUrl + '/listings/post/image', fileData);
};

const postListing = (data: PostForm): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/listings/post', data);
};

const removePost = (id: string, userId: string): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/listings/post/remove', { id, userId });
};

const updatePost = (data: {
  id: string;
  title: string;
  body: string;
  images: string[];
}): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/listings/post/update', data);
};

const ListingService = {
  fetchListingById,
  fetchListingsByCategory,
  handleListingLike,
  searchListings,
  uploadImages,
  postListing,
  fetchStickyListingByCategory,
  fetchListingByUserId,
  fetchFavListingByUserId,
  removePost,
  updatePost,
};

export default ListingService;

import React, { ReactElement, useState } from 'react';
import { miniAppUrl, subscriptionIllustration } from '../../constants/assetURL';
import Button from '../Button';
import './index.scss';
import SubscriptionService from '../../services/subscription';
import { toast, ToastContainer } from 'react-toastify';

interface Props {}

export default function AdsCol({}: Props): ReactElement {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const checkEmail = () => {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (regex.test(email)) {
      return true;
    }
    throw '无效的电子邮件';
  };

  const handleSubmit = () => {
    try {
      checkEmail();
      setLoading(true);
      try {
        SubscriptionService.getSubscriptionByEmail(email).then((result) => {
          if (result.data.data) {
            toast.success('您的邮箱已订阅');
          } else {
            SubscriptionService.createSubscription(email).then(() => {
              toast.success('已提交!');
              setSubscribed(true);
              setEmail('');
            });
          }
        });
      } catch {
        throw '订阅时发生错误';
      }
      setLoading(false);
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      <div className='ads-container'>
        <div className='empty ad-container'>
          <h1>广告位招租</h1>
          <p>此广告位今日被浏览1827次，点击172次</p>
          <span>点击在线购买广告位，支持消息通</span>
        </div>
        {/* <div className='mini-app ad-container'>
          <img src={miniAppUrl} alt='纽约消息通微信小程序'/>
        </div> */}
        <div className='ad-container subscription'>
          <img src={subscriptionIllustration} alt='关注消息通邮箱订阅' />
          <h3>订阅消息通邮件，获取每日资讯推送</h3>
          <p>
            我们会精选每周热门帖子发送到您的邮箱。请放心，我们绝不会发送垃圾邮件！
          </p>
          <input
            type='email'
            placeholder='请输入您的邮箱'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            block
            type={'secondary'}
            disabled={subscribed || loading}
            round
            onClick={handleSubmit}
          >
            {subscribed ? '已提交!' : loading ? '正在提交' : '订阅邮件'}
          </Button>
        </div>
        <div className='footer'>
          <p>商务合作: (646)639-7087</p>
          {/* <ul>
              <li>
                <a href='/contact'>联系我们</a>
              </li>
            </ul> */}
          <p>&copy;2021 纽约消息通 Info Connect Inc.</p>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

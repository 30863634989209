import axios, { AxiosResponse } from 'axios';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const getClientSecret = (
  amount: number,
  email: string
): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/payment/secret', { amount, email });
};

const PaymentService = {
  getClientSecret,
};

export default PaymentService;

import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import Layout from '../../components/Layout';
import ChevronLeft from '../../assets/icons/Chevron Left.svg';
import './index.scss';
import Button from '../../components/Button';
import { none, useHookstate } from '@hookstate/core';
import { useEffect } from 'react';
import Categories from '../../services/categories';
import { Category, CategoryMap, CategoryTitleMap } from '../../types/Listing';
import { toast, ToastContainer } from 'react-toastify';
import { parse } from '../../utils';
import ListingService from '../../services/listing';
import { PaymentPopup } from '../../components/PaymentPopup';
import { globalUserInfo, globalAuthToken } from '../../states';
import UserService from '../../services/users';
interface Props {}

export interface StickyPricing {
  key: string;
  price: number;
  title: string;
}

const stickyPricing: StickyPricing[] = [
  {
    key: '1day',
    price: 0,
    title: '1天',
  },
  {
    key: '3day',
    price: 0.99,
    title: '3天',
  },
  {
    key: '7day',
    price: 1.99,
    title: '7天',
  },
  {
    key: '14day',
    price: 2.99,
    title: '14天',
  },
  {
    key: '30day',
    price: 3.99,
    title: '30天',
  },
  {
    key: '60day',
    price: 5.99,
    title: '60天',
  },
  {
    key: '90day',
    price: 7.99,
    title: '90天',
  },
  {
    key: '180day',
    price: 9.99,
    title: '180天',
  },
  {
    key: '365day',
    price: 15.99,
    title: '365天',
  },
];

export interface PreviewFile extends File {
  src: string;
}

export interface PostForm {
  title: string;
  body: string;
  category: string;
  subCategory: string;
  phone: string;
  files?: string[];
  isSticky?: number;
  author: string;
}

const NewListingPage = (props: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const [stickySelected, setStickySelected] = React.useState(false);
  const [pricingSelection, setPricingSelection] = React.useState<null | string>(
    null
  );
  const [categories, setCategories] = React.useState<null | Category[]>(null);
  const [subCategory, setSubCategory] = React.useState<null | Category>(null);
  const user = globalUserInfo.value;
  const token = globalAuthToken.value;
  const formState = useHookstate<PostForm>({
    title: '',
    body: '',
    category: '',
    subCategory: '',
    phone: '',
    author: user?.id || '',
  });
  const previews = useHookstate<string[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [files, setFiles] = React.useState<any>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.files) {
      const previewList = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const preview = URL.createObjectURL(e.target.files[i]);
        previewList.push(preview);
      }

      setFiles(e.target.files);
      previews.merge(previewList);
    }
  };

  useEffect(() => {
    Categories.getCatalog().then((catalogResponse) => {
      const categoryValue = catalogResponse.data.data;
      const subCategoryValue = catalogResponse.data.data[0];
      setCategories(categoryValue);
      setSubCategory(subCategoryValue);
      formState.category.set(CategoryTitleMap[categoryValue[0].title]);
      formState.subCategory.set(subCategoryValue.categories[0].subCategory);
    });
  }, []);

  useEffect(() => {
    if (categories) {
      const found = categories.find(
        (v) => v.title === CategoryMap[formState.category.value]
      );
      if (found) {
        setSubCategory(found);
        formState.subCategory.set(found.categories[0].subCategory);
      }
    }
  }, [formState.value.category]);

  const handleValidation = (data: PostForm) => {
    if (!data.title) {
      toast.error('请输入标题');
      return false;
    }
    if (!data.body) {
      toast.error('请输入正文');

      return false;
    }
    if (!data.category) {
      toast.error('请选择分类');

      return false;
    }
    if (!data.subCategory) {
      toast.error('请选择副分类');

      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const data: PostForm = parse(formState.value);
    if (files) {
      const response = await ListingService.uploadImages(files);

      data.files = response.data.data;
      formState.files.set(response.data.data);
    }

    if (handleValidation(data)) {
      if (stickySelected && selectedPrice && selectedPrice.price > 0) {
        setIsOpen(true);
      } else {
        try {
          const id = await ListingService.postListing(data);
          if (id.data.data && token) {
            const userInfoResult = await UserService.fetchUserByToken(token);
            globalUserInfo.set(userInfoResult.data.data);
            history.push(`/listing/${id.data.data}`);
            toast.success('发布成功！');
          } else {
            throw '发布失败';
          }
        } catch (error) {
          setSubmitting(false);
          toast.error('好像出了点问题，请稍后再试');
        }
      }
    } else {
      setSubmitting(false);
    }
  };

  const selectedPrice = stickyPricing.find((v) => v.key === pricingSelection);

  useEffect(() => {
    setSubmitting(false);
  }, [isOpen]);

  return (
    <Layout
      showSidebar
      leftCol={
        <>
          <div className='title'>
            <h1>发表新帖子</h1>
          </div>
          <div className='new-post-container'>
            <div className='input-container'>
              <label>发表板块</label>
              <select
                onChange={(e) => {
                  formState.category.set(e.target.value);
                }}
              >
                {categories &&
                  categories.length &&
                  categories.map((v, i) => {
                    return (
                      <option value={CategoryTitleMap[v.title]} key={i}>
                        {v.title}
                      </option>
                    );
                  })}
              </select>
              <select
                onChange={(e) => formState.subCategory.set(e.target.value)}
              >
                {subCategory &&
                  subCategory.categories.length &&
                  subCategory.categories.map((v, i) => (
                    <option value={v.subCategory} key={i}>
                      {v.fullTitle}
                    </option>
                  ))}
              </select>
            </div>
            <div className='input-container'>
              <label>标题*</label>
              <input
                value={formState.value.title}
                onChange={(e) => formState.title.set(e.target.value)}
                type='text'
              />
            </div>
            <div className='input-container'>
              <label>正文*</label>
              <textarea
                value={formState.value.body}
                onChange={(e) => formState.body.set(e.target.value)}
                cols={30}
                rows={10}
              />
            </div>
            <div className='input-container'>
              <label>图片（最多九张）</label>
              <div className='preview'>
                {previews.value.map((v, i) => (
                  <div className='preview-image-container' key={i}>
                    <img src={v} alt='' />
                    <button
                      onClick={() => {
                        const nextState = [];
                        for (let j = 0; j < files.length; j++) {
                          if (j !== i) {
                            nextState.push(files[j]);
                          }
                        }
                        setFiles(nextState);
                        previews[i].set(none);
                      }}
                    >
                      移除
                    </button>
                  </div>
                ))}
              </div>
              <input
                multiple
                onChange={(e) => handleFileChange(e)}
                type='file'
              />
            </div>
            <div className='input-container'>
              <label>
                联系电话(请不要输入任何标点符号，10位手机号码如：6466397087
                即可)
              </label>
              <input
                type='tel'
                value={formState.value.phone}
                onChange={(e) => formState.phone.set(e.target.value)}
              />
            </div>
            <div className='input-container'>
              <div className='checkbox-container'>
                <label htmlFor='isSticky'>是否置顶</label>
                <input
                  id='isSticky'
                  name='isSticky'
                  type='checkbox'
                  checked={stickySelected}
                  onChange={(e) => setStickySelected(e.target.checked)}
                />
              </div>
            </div>
            {stickySelected && (
              <div className='input-container'>
                <label>置顶时长</label>
                <div className='radio-grid'>
                  {stickyPricing.map((v, i) => (
                    <div
                      className='radio-container'
                      key={i}
                      onClick={(e) => setPricingSelection(v.key)}
                    >
                      <label htmlFor={v.key}>{v.title}</label>
                      <div>
                        {v.price ? (
                          <span>${v.price}</span>
                        ) : (
                          <span className='free'>免费</span>
                        )}
                        <input
                          id={v.key}
                          type='radio'
                          name='duration'
                          value={v.key}
                          checked={v.key === pricingSelection}
                          onChange={(e) => setPricingSelection(v.key)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='button-container'>
              <Button
                disabled={submitting}
                type='primary'
                round
                size='large'
                width='96px'
                onClick={handleSubmit}
              >
                {submitting ? '发表中' : '发表'}
              </Button>
            </div>
          </div>
          <ToastContainer />
          {selectedPrice && (
            <PaymentPopup
              pricing={selectedPrice}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              data={formState.value}
            />
          )}
        </>
      }
    />
  );
};

export default NewListingPage;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './index.scss';

const SkeletonPlaceholder = () => {
  return (
    <div className='skeleton-container'>
      <h2>
        <Skeleton />
      </h2>
      <div className='item'>
        <Skeleton count={1} height={300} />
      </div>
      <div className='item'>
        <Skeleton count={1} height={300} />
      </div>
      <div className='item'>
        <Skeleton count={1} height={300} />
      </div>
      <div className='item'>
        <Skeleton count={1} height={300} />
      </div>
    </div>
  );
};

export default SkeletonPlaceholder;

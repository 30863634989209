import React, { ReactElement } from 'react';
import { IListing } from '../../types/Listing';
import { ReactComponent as LikeIcon } from '../../assets/icons/Heart.svg';
import { ReactComponent as FireIcon } from '../../assets/icons/Fire.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/Share.svg';
import { ReactComponent as PictureIcon } from '../../assets/icons/Picture.svg';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import classNames from 'classnames';
import { globalUserInfo } from '../../states';
import listingService from '../../services/listing';
import Layout from '../../components/Layout';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
interface Props {
  userId: string;
}

export default function FavListing({ userId }: Props): ReactElement {
  const [data, setData] = React.useState<null | IListing[]>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [canLoadMore, setCanLoadMore] = React.useState(true);

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const response = await listingService.fetchFavListingByUserId(
        userId,
        page
      );
      if (response.data.data) {
        setData(response.data.data);
        setLoading(false);
      } else {
        throw '获取帖子数据失败';
      }
    } catch (error) {
      toast.error(error);
    }
  };
  React.useEffect(() => {
    fetchData(1);
  }, [userId]);

  const changePage = () => {
    if (canLoadMore) {
      setCanLoadMore(false);
      setCurrentPage(currentPage + 1);

      setTimeout(() => {
        setCanLoadMore(true);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      loadAndMergeData();
    }
  }, [currentPage]);

  const loadAndMergeData = async () => {
    let nextPageListings;
    const page = currentPage;
    if (window.scrollY > 200 && data) {
      nextPageListings = await listingService.fetchFavListingByUserId(
        userId,
        page + 1
      );

      const combinedListings = [...data, ...nextPageListings.data.data];
      setData(combinedListings);
    }
  };
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={changePage}
      hasMore={canLoadMore}
      loader={<Skeleton count={1} height={300} key='loader' />}
    >
      <div className='listings-container'>
        {loading ? (
          <SkeletonPlaceholder key='loader' />
        ) : (
          data &&
          data.map((data, index) => (
            <div className='listing-item-container' key={index}>
              <div className='title-container'>
                <div className='flex items-center'>
                  {(data.isSticky || data.isOriginal) && (
                    <span
                      className={classNames(
                        'flex items-center mr-2 bg-blue-600 text-white px-2 rounded-lg text-sm h-6',
                        {
                          'bg-green-600': data.isSticky,
                        }
                      )}
                    >
                      {data.isSticky ? '置顶' : data.isOriginal && '原创'}
                    </span>
                  )}
                  <Link to={'/listing/' + data._id} target='_blank'>
                    <h4>
                      {data.title}{' '}
                      {data.images.length ? (
                        <>
                          <PictureIcon />
                        </>
                      ) : null}
                    </h4>
                  </Link>
                </div>
              </div>
              {data.images.length ? (
                <div className='images-container'>
                  <img src={data.images[0]} alt={data.title} />
                  <span>&copy;纽约消息通</span>
                </div>
              ) : null}
              <div className='body'>
                {data.body.length > 100
                  ? data.body.slice(0, 100) + '...'
                  : data.body}
              </div>
              <div className='footer'>
                <span>{data.fromNow}</span>
              </div>
            </div>
          ))
        )}
      </div>
    </InfiniteScroll>
  );
}

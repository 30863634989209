import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Auth from '../../services/auth';
import { GoogleLogin } from 'react-google-login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../../services/users';
import GoogleSvg from '../../assets/social/google.svg';
import FacebookSvg from '../../assets/social/facebook.svg';
import InstagramSvg from '../../assets/social/ins.svg';
import TwitterSvg from '../../assets/social/twitter.svg';
import LinkedinSvg from '../../assets/social/linkedin.svg';
import Button from '../../components/Button';
import { globalUserInfo } from '../../states';

interface Props {}

export default function SignupForm({}: Props): ReactElement {
  const history = useHistory();
  const googleKey = process.env.REACT_APP_GOOGLE_OAUTH || '';

  const [phone, setPhone] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleGoogle = async (response: any) => {
    const result = await Auth.responseGoogle(response);
    if (result.data.success) {
      window.localStorage.setItem('token', result.data.token);
      const userInfo = await UserService.fetchUserByToken(result.data.token);
      globalUserInfo.set(userInfo.data.data);
      toast.success('登录成功', { autoClose: 1500 });
      setTimeout(() => {
        history.push('/');
      }, 2000);
    } else {
      toast.error('登录失败');
    }
  };
  const loginGoogleFail = () => {
    toast.warn('Google登录已取消');
  };
  const handleSendCode = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (phone === '') {
      toast.warn('请填写手机号');
      return false;
    }
    const result = await Auth.getSMSCode(phone);
    if (result.data.success) {
      window.localStorage.setItem('sid', result.data.data);
      toast.success('发送成功');
    } else {
      toast.error('手机号已被注册');
    }
  };
  const handleRegister = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);
    if (phone === '' || password === '' || code === '') {
      toast.warn('请填写所有信息');
      setLoading(false);
      return false;
    }
    const sid = window.localStorage.getItem('sid') || '';
    if (sid === '') {
      toast.error('验证失败');
      setLoading(false);
      return false;
    } else {
      const isCode = await Auth.verifyCode(phone, code, sid);
      if (isCode.data.success && isCode.data.data === 'approved') {
        const result = await Auth.handleRegister(phone, password);
        if (result.data.success) {
          toast.success('注册成功，请登录', { autoClose: 1500 });
          setTimeout(() => {
            setLoading(false);
            history.push('/signin');
          }, 2000);
        } else {
          toast.error('注册失败');
          setLoading(false);
          return false;
        }
      } else {
        toast.warn('验证码不正确');
        setLoading(false);
        return false;
      }
    }
  };

  return (
    <form>
      <h1>注册</h1>
      <ToastContainer />
      <div className='input-container'>
        <label htmlFor='phone'>手机号</label>
        <input
          id='phone'
          type='text'
          placeholder='请输入您的手机号'
          onChange={(e) => setPhone(e.target.value)}
        />
        <div className='verification-code-container'>
          <input
            type='text'
            placeholder='输入验证码'
            onChange={(e) => setCode(e.target.value)}
          />
          <Button type='secondary' onClick={handleSendCode}>
            发送验证码
          </Button>
        </div>
      </div>
      <div className='input-container'>
        <label htmlFor='password'>密码</label>
        <input
          type='password'
          id='password'
          placeholder='设置密码'
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button type='primary' block disabled={loading} onClick={handleRegister}>
        {loading ? '注册中...' : '注册'}
      </Button>
      <hr />
      <GoogleLogin
        clientId={googleKey}
        buttonText='使用Google登录'
        onSuccess={handleGoogle}
        onFailure={loginGoogleFail}
        cookiePolicy={'single_host_origin'}
      />
      {/* <h2>使用社交账号注册</h2>
      <ul className='social-media-container'>
        <li>
          <img src={GoogleSvg} alt='google' />
        </li>
        <li>
          <img src={FacebookSvg} alt='facebook' />
        </li>
        <li>
          <img src={InstagramSvg} alt='instagram' />
        </li>
        <li>
          <img src={TwitterSvg} alt='twitter' />
        </li>
        <li>
          <img src={LinkedinSvg} alt='linkedin' />
        </li>
      </ul> */}
      <hr />
      <span>
        已有账号? 直接 <Link to='/signin'>登录</Link>
      </span>
    </form>
  );
}

import React, { ReactElement, SetStateAction } from 'react';
import Layout from '../../components/Layout';
import io from 'socket.io-client';
import Service from '../../services/config';
import { Message, Room } from '../../types/Message';
import ChatMessages from '../../services/chatMessage';
import './index.scss';

interface Props {}

export default function ChatRoomPage({}: Props): ReactElement {
  const [messages, setMessages] = React.useState<Array<Message>>([]);
  const [socket, setSocket] = React.useState<SocketIOClient.Socket | undefined>(
    undefined
  );
  const [rooms, setRooms] = React.useState<Array<Room>>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [currentRoom, setCurrentRoom] = React.useState<string>('romance');
  const [page, setPage] = React.useState<number>(0);

  React.useEffect(() => {
    const fetchData = async () => {
      const rooms = await ChatMessages.getRoomsList();
      setRooms(rooms.data.data);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const historyMessages = await ChatMessages.getHistoryMessages(
        currentRoom
      );
      setMessages(historyMessages.data.data);
    };

    fetchData();
  }, [currentRoom]);

  React.useEffect(() => {
    const socketConnection: SocketIOClient.Socket = io(
      Service.getAPIEndpoint()
    );
    setSocket(socketConnection);
    socketConnection.on('chat message', (newMessage: Message) => {
      setMessages((prevState) => [...prevState, newMessage]);
    });
  }, []);

  const handleSendMessage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    if (socket && socket.emit) {
      ChatMessages.sendMessage(
        '5c96586f48f7072c232ec04e',
        inputValue,
        currentRoom
      ).then(() => {
        setInputValue('');
      });
    }
  };

  return (
    <Layout>
      <div className='chatroom-container'>
        <div className='rooms-list-container grid-container'>
          <h2>聊天室列表</h2>
          <ul>
            {rooms.map((v) => (
              <li
                style={{ fontWeight: currentRoom === v.id ? 'bold' : 100 }}
                onClick={() => setCurrentRoom(v.id)}
                key={v.id}
              >
                {v.title}
              </li>
            ))}
          </ul>
        </div>
        <div className='messages-container grid-container'>
          <h2>聊天窗口</h2>
          <div className='messages-list'>
            {messages.map((v, i) => (
              <>
                <span>{v.userId}:</span>
                <p key={i}>{v.content}</p>
              </>
            ))}
          </div>
        </div>
        <div className='users-container grid-container'>
          <h2>在线用户</h2>
        </div>
      </div>
      <form>
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          type='text'
        />
        <button onClick={handleSendMessage}>Send</button>
      </form>
    </Layout>
  );
}

import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import './index.scss';
import AdsCol from '../AdsCol';
import NewsSection from '../NewsSection';
import { ENewsType } from '../../types/News';
import Categories from '../../services/categories';
import { globalCategoryState } from '../../states';

interface Props {
  leftCol?: ReactNode;
  children?: ReactNode;
  rightCol?: ReactNode;
  light?: boolean;
  showSidebar?: boolean;
}

const calculateMarginLeft = (): number => {
  const MOBILE_BREAK_POINT = 1024;
  const DESKTOP_BREAK_POINT = 1440;
  const SIDEBAR_WIDTH = 240;
  switch (true) {
    case window.innerWidth < MOBILE_BREAK_POINT:
      return 0;
    case window.innerWidth < DESKTOP_BREAK_POINT:
      return SIDEBAR_WIDTH;
    default:
      let offset = SIDEBAR_WIDTH;
      const diff = window.innerWidth - DESKTOP_BREAK_POINT;
      if (diff > 0) {
        offset -= diff / 2;
      }
      return offset > 0 ? offset : 0;
  }
};

export default function Layout({
  leftCol,
  rightCol = <AdsCol />,
  light,
  children,
  showSidebar,
}: Props): ReactElement {
  const initialOffset = showSidebar ? calculateMarginLeft() : 0;
  const [offsetLeft, setOffsetLeft] = React.useState<number>(initialOffset);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (showSidebar) {
        setOffsetLeft(calculateMarginLeft());
      }
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Categories.getCatalog();
      if (response.data.success) {
        globalCategoryState.set(response.data.data);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classnames('layout', { light })}>
      <Navbar />
      {showSidebar && <Sidebar />}
      <main>
        <div
          className={classnames('page-container', {
            ['single-col']: !showSidebar,
          })}
          style={{
            marginLeft: `${offsetLeft}px`,
            width: `calc(100% - ${offsetLeft}px`,
          }}
        >
          {leftCol && rightCol ? (
            <div className='page-grid'>
              <div className='page-col'>{leftCol}</div>
              <div className='page-col'>{rightCol}</div>
            </div>
          ) : (
            children
          )}
        </div>
      </main>
    </div>
  );
}

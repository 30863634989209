import React, { ReactElement } from 'react';
import './index.scss';
import SearchIcon from '../../assets/icons/Search.svg';

interface Props {
  placeholder?: string;
}

export default function SearchBar({ placeholder }: Props): ReactElement {
  return (
    <form className='search-container'>
      <img src={SearchIcon} alt='search-icon' />
      <input
        type='search'
        placeholder={placeholder || '皇后区租房、餐馆企台'}
      />
    </form>
  );
}

import axios, { AxiosResponse } from 'axios';
import Service from './config';
const apiUrl = Service.getAPIEndpoint();

const responseGoogle = (response: any):Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/auth/google',
    {
      email:response.profileObj.email,
      avatarUrl:response.profileObj.imageUrl,
      nickName:response.profileObj.name,
    },
  );
}

const getSMSCode = (phone:string):Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/auth/getCode',
    {
      phone,
    },
  );
}

const verifyCode = (phone:string,code:string,sid:string):Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/auth/verifyCode',
    {
      phone,
      code,
      sid,
    },
  );
}

const handleLogin = (phone:string,password:string):Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/auth/login',
    {
      phone,
      password
    },
  );
}

const handleRegister = (phone:string,password:string):Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/auth/register',
    {
      phone,
      password
    },
  );
}

const Auth = {
    responseGoogle,
    getSMSCode,
    verifyCode,
    handleLogin,
    handleRegister,
};

export default Auth;
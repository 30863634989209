import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Category } from '../../types/Listing';
import ChevronDown from '../../assets/icons/Chevron Down.svg';
import ChevronUp from '../../assets/icons/Chevron Up.svg';
import './CategoryMenu.scss';
import { useHistory } from 'react-router-dom';
import { iconUrlPrefix } from '../../constants/assetURL';
import { useState } from '@hookstate/core';
import { globalCategoryState } from '../../states';

interface Props {
  active: boolean;
}

export default function CategoryMenu({ active }: Props): ReactElement {
  const categoryState = useState(globalCategoryState);
  const [activeSubCategory, setActiveSubCategory] = React.useState<
    string | null
  >(null);
  const history = useHistory();

  return (
    <div
      className={classnames('nav-category-menu', {
        active,
      })}
    >
      {categoryState.value &&
        categoryState.value.map((v) => {
          const isCurrentCategory = activeSubCategory === v.title;
          const subCategoryList =
            activeSubCategory &&
            categoryState?.value?.find((c) => c.title === activeSubCategory)
              ?.categories;
          return (
            <div key={v.title} className='category-container'>
              <span
                className={classnames(isCurrentCategory)}
                onClick={() =>
                  isCurrentCategory
                    ? setActiveSubCategory(null)
                    : setActiveSubCategory(v.title)
                }
              >
                {v.title}
                <img
                  src={isCurrentCategory ? ChevronDown : ChevronUp}
                  alt='Chevron'
                />
              </span>
              {isCurrentCategory && (
                <div className='subcategory-container'>
                  {subCategoryList &&
                    subCategoryList.map((s) => {
                      return (
                        <div
                          key={s.shortTitle}
                          className='subcategory-list'
                          onClick={() => history.push('/')}
                        >
                          <img src={iconUrlPrefix + s.icon} alt={s.fullTitle} />
                          <span>{s.fullTitle}</span>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

import React, { ReactElement } from 'react';
import { IListing } from '../../types/Listing';
import { NewspaperIcon } from '@heroicons/react/outline';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import classNames from 'classnames';
import { globalUserInfo } from '../../states';
import listingService from '../../services/listing';
import Layout from '../../components/Layout';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import MyListing from './MyListing';
import FavListing from './FavListing';
interface Props {}

export default function Profile(): ReactElement {
  const userId = location.pathname.split('/profile/')[1];
  const [currentTab, setCurrentTab] = React.useState('myListing');
  return (
    <Layout>
      <ToastContainer />
      <div className='max-w-4xl mx-auto'>
        <div className='flex'>
          <NewspaperIcon className='flex-shrink-0 h-6 w-6 text-black-400' />
          <div className='ml-4'>
            <p className='text-base text-gray-900 break-normal inline-block whitespace-nowrap'>
              我的帖子
            </p>
          </div>
        </div>
        <div className='flex text-sm text-gray-400 mt-4'>
          <span
            style={
              currentTab === 'myListing' ? { color: '#1AAD19' } : undefined
            }
            onClick={() => setCurrentTab('myListing')}
            className='cursor-pointer'
          >
            已发表
          </span>
          <span
            style={
              currentTab === 'favListing' ? { color: '#1AAD19' } : undefined
            }
            onClick={() => setCurrentTab('favListing')}
            className='ml-5 cursor-pointer'
          >
            已收藏
          </span>
        </div>
        <div className='mt-4'>
          {currentTab === 'myListing' ? (
            <MyListing userId={userId} />
          ) : (
            <FavListing userId={userId} />
          )}
        </div>
      </div>
    </Layout>
  );
}

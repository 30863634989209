import axios, { AxiosResponse } from 'axios';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const getCatalog = (): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/categories/get/all');
};

const Categories = {
  getCatalog,
};

export default Categories;

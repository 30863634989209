import axios, { AxiosResponse } from 'axios';
import { ENewsType } from '../types/News';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const getNewsFromAllSources = (page: number): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/news/get/all', {
    params: { page },
  });
};

const getNewsByCategory = (
  category: ENewsType,
  page?: number,
  limit?: number,
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/news/get/list', {
    params: {
      page: page || 1,
      category,
      limit,
    },
  });
};

const getNewsDetail = (
  id: string,
  format?: 'html' | 'wxml',
): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/news/get', {
    params: {
      id,
      format,
    },
  });
};

const likeNews = (id: string): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/news/post/like', {
    id,
  });
};

const shareNews = (id: string): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/news/post/share', {
    id,
  });
};

const News = {
  getNewsByCategory,
  getNewsDetail,
  likeNews,
  shareNews,
  getNewsFromAllSources,
};

export default News;

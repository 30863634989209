const getAPIEndpoint = (): string => {
  const env = process.env.REACT_APP_NODE_ENV;
  switch (env) {
    case 'development':
      return API.dev;
    case 'production':
      return API.prod;
    default:
      return API.prod;
  }
};

const iconUrlPrefix = 'https://s3.amazonaws.com/pocket-life-icons/';

const API = {
  dev: 'http://localhost:3005',
  prod: 'https://api.egiang.com',
};

const domain = 'https://egiang.com';

const Service = {
  getAPIEndpoint,
  iconUrlPrefix,
  API,
  domain,
};

export default Service;

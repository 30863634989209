import axios, { AxiosResponse } from 'axios';
import Service from './config';

const apiUrl = Service.getAPIEndpoint();

const sendMessage = (
  userId: string,
  content: string,
  roomId: string
): Promise<AxiosResponse> => {
  return axios.post(apiUrl + '/chat', {
    userId,
    content,
    roomId,
  });
};

const getHistoryMessages = (roomId: string): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/chat/room', {
    params: {
      roomId,
    },
  });
};

const getRoomsList = (): Promise<AxiosResponse> => {
  return axios.get(apiUrl + '/chat/rooms', {});
};

const ChatMessages = {
  sendMessage,
  getHistoryMessages,
  getRoomsList,
};

export default ChatMessages;

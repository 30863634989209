import React, { ReactElement, ReactNode } from 'react';
import Logo from '../Logo';
import './index.scss';

interface Props {
  form?: ReactNode;
}

export default function SplashLayout({ form }: Props): ReactElement {
  return (
    <div className='splash-layout'>
      <div className='splash-container'>
        <h2>随时随地发现纽约精彩讯息</h2>
        <ul>
          <li>slogan 1</li>
          <li>slogan 2</li>
          <li>slogan 3</li>
        </ul>
      </div>
      <div className='form-container'>
        <Logo />
        {form}
      </div>
    </div>
  );
}

import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Badge from '../Badge';
import Button from '../Button';
import Logo from '../Logo';
import SearchBar from '../SearchBar';
import Hamburger from './Hamburger';
import './index.scss';
import Dropdown from './Dropdown';
import { globalAuthToken } from '../../states';
interface Props {}

export default function Navbar({}: Props): ReactElement {
  const history = useHistory();
  const token = globalAuthToken.value;
  const [openDropdown, setDropdown] = React.useState(false);
  return (
    <nav>
      <div className='nav-container'>
        <div className='hamburger-menu show-sm'>
          <Hamburger />
        </div>
        <div className='nav-left'>
          <Logo withText />
          <div className='links-container show-lg'>
            <ul>
              <li>
                <Link to='/'>首页</Link>
              </li>
              {/* <li>
                <Link to='/listings?category=rental&subCategory=皇后区&page=1'>
                  分类信息 <Badge>99+</Badge>
                </Link>
              </li> */}
              {/* <li>
                <Link to='/news'>
                  今日头条<Badge>99+</Badge>
                </Link>
              </li> */}
              {/* <li>
                <Link to='#'>
                  新冠疫情追踪<Badge>实时</Badge>
                </Link>
              </li>
              <li>
                <Link to='/chat'>
                  聊天室<Badge>168人</Badge>
                </Link>
              </li> */}
            </ul>
          </div>
          <div className='search-bar-container hide-sm'>
            <SearchBar />
          </div>
        </div>
        {token ? (
          <div>
            <div className='nav-right show-lg'>
              <Button round onClick={() => history.push('/create/listing')}>
                刊登广告
              </Button>
              <Dropdown openDropdown={openDropdown} setDropdown={setDropdown} />
            </div>
            <div className='show-sm'>
              <Dropdown openDropdown={openDropdown} setDropdown={setDropdown} />
            </div>
          </div>
        ) : (
          <>
            <div className='nav-right show-lg'>
              <Button round onClick={() => history.push('/signin')}>
                登录
              </Button>
              <Button round onClick={() => history.push('/signup')}>
                注册
              </Button>
            </div>
            <Button
              className='user-account show-sm'
              size='small'
              onClick={() => history.push('/signin')}
            >
              登录
            </Button>
          </>
        )}
      </div>
    </nav>
  );
}

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
  }, [pathname]);

  return null;
}

import { createState } from '@hookstate/core';
import { Category, CategoryData } from '../types/Listing';
import { IUser } from '../types/User';

export const persistenceKeyMap = {
  userInfo: 'infoConnect.userInfo',
  authToken: 'infoConnect.authToken',
};

export const globalAlert = createState<{
  show: boolean;
  title: string;
  description?: string;
  type: 'info' | 'warning';
  actionFunction: () => Promise<void>;
}>({
  show: false,
  title: '',
  description: '',
  type: 'info',
  actionFunction: async () => {},
});

export const globalCategoryState = createState<Category[] | null>(null);
export const globalUserInfo = createState<IUser | null>(null);
export const globalAuthToken = createState<string | null>(null);

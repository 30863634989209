export enum ENewsType {
  SpeedyNews = 'speedyNews',
  China = 'china',
  Entertainment = 'entertainment',
}

export interface INewsData {
  _id: string;
  images: string[];
  category: ENewsType;
  date: string;
  title: string;
  views: number;
  likes: number;
  shares: number;
}

export interface INewsDetails {
  category: ENewsType;
  fetchDate: string;
  htmlContent: {
    body: string;
    images: string[];
    title: string;
  };
  likes: number;
  originalDate: string;
  shares: number;
  views: number;
}

export interface INewsMap {
  [ENewsType.SpeedyNews]: string;
  [ENewsType.China]: string;
  [ENewsType.Entertainment]: string;
}

export type TNewsMapEntries = [any, any];

export const NEWS_TITLE_MAP: INewsMap = {
  [ENewsType.SpeedyNews]: '新闻速递',
  [ENewsType.China]: '中国新闻',
  [ENewsType.Entertainment]: '娱乐体育',
};

import React, { ReactElement } from 'react';
import { IListing } from '../../types/Listing';
import { ReactComponent as LikeIcon } from '../../assets/icons/Heart.svg';
import { ReactComponent as FireIcon } from '../../assets/icons/Fire.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/Share.svg';
import { ReactComponent as PictureIcon } from '../../assets/icons/Picture.svg';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import classNames from 'classnames';
import { globalUserInfo, globalAlert, globalAuthToken } from '../../states';
import listingService from '../../services/listing';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { useState } from '@hookstate/core';
import UserService from '../../services/users';
import EditModal from './EditModal';
interface Props {
  userId: string;
}

export default function MyListing({ userId }: Props): ReactElement {
  const [data, setData] = React.useState<null | IListing[]>(null);
  const [openEdit, setEditOpen] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [editData, setEditData] = React.useState<null | IListing>(null);
  const [updatedData, setUpdatedData] = React.useState<null | IListing>(null);
  const [updatedIndex, setUpdatedIndex] = React.useState<null | number>(null);
  const userInfo = globalUserInfo.value;
  const alert = useState(globalAlert);
  const token = globalAuthToken.value;
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [canLoadMore, setCanLoadMore] = React.useState(true);

  React.useEffect(() => {
    if (data && updatedIndex !== null && updatedData) {
      const oldData = data;
      oldData[updatedIndex] = updatedData;
      setData([...oldData]);
      console.log(updatedData, 'updatedData');
    }
  }, [updatedData]);

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const response = await listingService.fetchListingByUserId(userId, page);
      if (response.data.data) {
        setData(response.data.data);
        setLoading(false);
      } else {
        throw '获取帖子数据失败';
      }
    } catch (error) {
      toast.error(error);
    }
  };
  React.useEffect(() => {
    if (userId) {
      fetchData(1);
    }
  }, [userId]);

  const changePage = () => {
    if (canLoadMore) {
      setCanLoadMore(false);
      setCurrentPage(currentPage + 1);

      setTimeout(() => {
        setCanLoadMore(true);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      loadAndMergeData();
    }
  }, [currentPage]);

  const loadAndMergeData = async () => {
    let nextPageListings;
    const page = currentPage;
    if (window.scrollY > 200 && data) {
      nextPageListings = await listingService.fetchListingByUserId(
        userId,
        page + 1
      );

      const combinedListings = [...data, ...nextPageListings.data.data];
      setData(combinedListings);
    }
  };

  const handleEdit = (data: IListing, index: number) => {
    setUpdatedIndex(index);
    setEditData({ ...data });
    setEditOpen(true);
  };

  const handleDelete = async (id: string, index: number) => {
    try {
      await listingService.removePost(id, userId);
      const oldArr = data;
      if (oldArr) {
        oldArr.splice(index, 1);
        setData([...oldArr]);
        if (token) {
          const userInfoResult = await UserService.fetchUserByToken(token);
          globalUserInfo.set(userInfoResult.data.data);
          alert.show.set(false);
          toast.success('删除成功');
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      <EditModal
        openEdit={openEdit}
        setEditOpen={setEditOpen}
        data={editData}
        setUpdatedData={setUpdatedData}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={changePage}
        hasMore={canLoadMore}
        loader={<Skeleton count={1} height={300} key='loader' />}
      >
        <div className='listings-container'>
          {loading ? (
            <Skeleton count={3} height={200} />
          ) : (
            data &&
            data.map((data, index) => (
              <div className='listing-item-container' key={index}>
                <div className='title-container'>
                  <div className='flex items-center'>
                    {(data.isSticky || data.isOriginal) && (
                      <span
                        className={classNames(
                          'flex items-center mr-2 bg-blue-600 text-white px-2 rounded-lg text-sm h-6',
                          {
                            'bg-green-600': data.isSticky,
                          }
                        )}
                      >
                        {data.isSticky ? '置顶' : data.isOriginal && '原创'}
                      </span>
                    )}
                    <Link to={'/listing/' + data._id} target='_blank'>
                      <h4>
                        {data.title}{' '}
                        {data.images.length ? (
                          <>
                            <PictureIcon />
                          </>
                        ) : null}
                      </h4>
                    </Link>
                  </div>
                </div>
                {data.images.length ? (
                  <div className='images-container'>
                    <img src={data.images[0]} alt={data.title} />
                    <span>&copy;纽约消息通</span>
                  </div>
                ) : null}
                <div className='body'>
                  {data.body.length > 100
                    ? data.body.slice(0, 100) + '...'
                    : data.body}
                </div>
                <div className='footer'>
                  <span>{data.fromNow}</span>
                  {userInfo?.id === userId ? (
                    <div className='reaction-list'>
                      <div
                        className='reaction'
                        onClick={() => handleEdit(data, index)}
                      >
                        <PencilAltIcon className='' />
                        <span>修改</span>
                      </div>
                      <div
                        className='reaction'
                        onClick={() =>
                          alert.set({
                            title: '警告',
                            description: '确定删除这个帖子吗？',
                            show: true,
                            type: 'warning',
                            actionFunction: () => handleDelete(data._id, index),
                          })
                        }
                      >
                        <TrashIcon />
                        <span>删除</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
    </>
  );
}

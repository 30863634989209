import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router';
import AdsCol from '../../components/AdsCol';
import Layout from '../../components/Layout';
import SkeletonPlaceholder from '../../components/SkeletonPlaceholder';
import News from '../../services/news';
import { INewsData } from '../../types/News';
import { toast, ToastContainer } from 'react-toastify';

interface Props {}

export default function NewsPage({}: Props): ReactElement {
  const [data, setData] = useState<null | INewsData[]>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const response = await News.getNewsFromAllSources(page);
      if (response.data.data) {
        setData(response.data.data);
        setLoading(false);
      } else {
        throw '获取新闻数据失败';
      }
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const changePage = () => {
    if (canLoadMore) {
      setCanLoadMore(false);
      setCurrentPage(currentPage + 1);

      setTimeout(() => {
        setCanLoadMore(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      loadAndMergeData();
    }
  }, [currentPage]);

  const loadAndMergeData = async () => {
    let nextPageListings;
    const page = currentPage;
    if (window.scrollY > 200 && data) {
      nextPageListings = await News.getNewsFromAllSources(page + 1);

      const combinedListings = [...data, ...nextPageListings.data.data];
      setData(combinedListings);
    }
  };

  console.log(data?.length, loading, currentPage);
  return (
    <Layout
      showSidebar
      leftCol={
        <div className='listing-left-col'>
          <ToastContainer />

          <InfiniteScroll
            pageStart={0}
            loadMore={changePage}
            hasMore={canLoadMore}
            loader={<Skeleton count={1} height={300} key='loader' />}
          >
            <div className='listings-container'>
              {loading ? (
                <SkeletonPlaceholder key='loader' />
              ) : (
                data && data.map((v, i) => <p key={i}>{i}</p>)
              )}
            </div>
          </InfiniteScroll>
        </div>
      }
    />
  );
}

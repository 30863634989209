import { ENewsType } from './News';

export interface IListing {
  body: string;
  fromNow: string;
  images: string[];
  isSticky: boolean;
  isOriginal: boolean;
  likes: number;
  shares: number;
  subCategory: ESubCategory;
  title: string;
  views: number;
  _id: string;
  phone: string;
}

export interface CategoryData {
  data: Category[];
  success: boolean;
}

export interface Category {
  title: string;
  categories: SubCategory[];
}

export interface SubCategory {
  category: ECategory;
  fullTitle: string;
  icon: string;
  shortTitle: string;
  subCategory: ESubCategory | ENewsType;
}

export const CategoryMap: { [key: string]: string } = {
  rental: '租房买房',
  kenvicJobs: '肯维职库',
  travel: '出行',
  hiring: '招聘求职',
  buyAndSell: '商品交易',
  professional: '专业人士',
  community: '社区信息',
  news: '新闻',
  training: '教育培训',
};

export const CategoryTitleMap: { [key: string]: string } = {
  租房买房: 'rental',
  肯维职库: 'kenvicJobs',
  出行: 'travel',
  招聘求职: 'hiring',
  商品交易: 'buyAndSell',
  专业人士: 'professional',
  社区信息: 'community',
  新闻: 'news',
  教育培训: 'training',
};

export enum ECategory {
  RENTAL = 'rental',
  REALTY = 'realty',
  TRAVEL = 'travel',
  HIRING = 'hiring',
  COMMUNITY = 'community',
  BUYANDSELL = 'buyAndSell',
  PROFESSIONAL = 'professional',
  TRAINING = 'training',
  NEWS = 'NEWS',
}

export enum ESubCategory {
  ZUZUWANG = '租租网',
  PREP = 'SAT、各科补习',
  VOCATIONAL_TRAINING = '职业、英语、才艺',
  SHIPPING = '快递货运',
  PHOTOGRAPHY = '摄影、护照',
  DRIVING_SCHOOL = '考牌练车',
  MEDICAL = '中医、保健',
  PHYSICAL_THERAPY = '推拿按摩理疗',
  SPA = '美容发廊Spa',
  PRINTING = '印刷、招牌',
  WEB_DESIGN = '网页、软件设计',
  SOFTWARE_HARDWARE = '电脑、软件、硬件',
  NOTARY = '翻译公证',
  VISA = '留学签证',
  CITIZENSHIP = '公民入籍',
  INVESTMENT = '投资、理财、贷款',
  INSURANCE = '各类保险',
  ACCOUNTING = '会计报税',
  LAW_FIRMS = '各类律师',
  MOVING = '搬家',
  HVAC = '冷暖水电',
  CONSTRUCTION = '装修服务',
  OTHERS = '其他',
  COMPUTERS = '电脑',
  CARS = '汽车',
  PHONES = '手机',
  SECOND_HAND = '二手',
  INTERNET_PROVIDERS = '网络公司',
  SALES_PART_TIMER = '销售、兼职、代购',
  JOB_HUNTING = '求职',
  CHURCH = '教会、宗教',
  DAY_CARE = '幼儿园、托儿所',
  COMMUNITY = '社区信息',
  ROMANCE = '交友征婚',
  MASSAGES = '按摩推拿',
  RESTAURANT = '餐馆',
  BEAUTY_SALON = '美容美甲',
  TAXI = '电召车、机场接送',
  CAR_POOL = '顺风车',
  OTA = '机票、旅行社',
  APPRAISAL = '验屋服务',
  REAL_ESTATE = '房屋买卖',
  OFFICES = '商铺、办公楼',
  LI_NJ = '长岛、新泽西、其他区',
  MANHATTAN = '曼哈顿',
  BROOKLYN = '布碌仑',
  FLUSHING = '法拉盛',
  GREATER_QUEENS = '艾姆赫斯特、木边、可乐娜',
  QUEENS = '皇后区',
}

import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import './index.scss';

interface Props {
  type?: 'primary' | 'secondary' | 'tertiary';
  block?: boolean;
  children: ReactNode;
  width?: string;
  className?: string;
  round?: boolean;
  size?: 'large' | 'small' | 'huge';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

export default function Button({
  type = 'primary',
  disabled,
  children,
  block,
  width,
  className,
  onClick,
  size = 'large',
  round,
}: Props): ReactElement {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classnames(`button ${className}`, {
        block,
        [type]: type,
        round,
        large: size === 'large',
        small: size === 'small',
        huge: size === 'huge',
      })}
      style={{ width: width || (block ? '100%' : 'auto') }}
    >
      {children}
    </button>
  );
}

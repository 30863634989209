import React, { ReactElement } from 'react';
import { ECategory, SubCategory, ESubCategory } from '../../types/Listing';
import classnames from 'classnames';
import { ReactComponent as ChevronDown } from '../../assets/icons/Chevron Down.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/Chevron Up.svg';
import { iconUrlPrefix } from '../../constants/assetURL';
import { useHistory } from 'react-router-dom';
import { ENewsType } from '../../types/News';
import { parse } from 'query-string';

interface Props {
  title: string;
  categories: SubCategory[];
}

export default function MenuItem({ title, categories }: Props): ReactElement {
  const [isOpen, setIsOpen] = React.useState<Boolean>(false);
  const history = useHistory();

  const { category, subCategory, type } = React.useMemo(() => {
    return parse(history.location.search);
  }, [history.location]);

  const handleHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSubCategoryClick = (
    category: ECategory,
    subCategory: ESubCategory | ENewsType
  ) => {
    if (category === ECategory.NEWS) {
      history.push(`/news?type=${subCategory}`);
    } else {
      history.push(
        `/listings?category=${category}&subCategory=${subCategory}&page=1`
      );
    }
  };

  return (
    <div className='menu-item'>
      <div onClick={handleHeaderClick} className='menu-item-header'>
        <span>{title}</span>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      <div
        className={classnames('menu-item-list', {
          active: isOpen,
        })}
      >
        {categories.map((v) => {
          let active;
          if (v.category === ECategory.NEWS) {
            active = type === v.subCategory;
          } else {
            active = category === v.category && subCategory === v.subCategory;
          }
          return (
            <div
              onClick={() => handleSubCategoryClick(v.category, v.subCategory)}
              key={v.subCategory}
              className={classnames('submenu-item', {
                active,
              })}
            >
              <img src={iconUrlPrefix + v.icon} alt={v.fullTitle} />
              <span>{v.fullTitle}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { ReactElement } from 'react';
import { ReactComponent as Heart } from '../../assets/icons/Heart.svg';
import { ReactComponent as Share } from '../../assets/icons/Share.svg';
import './index.scss';

interface Props {
  likes: number;
  onLike?: () => void;
  shares: number;
  onShare?: () => void;
}

export default function Reaction({
  likes,
  shares,
  onLike,
  onShare,
}: Props): ReactElement {
  const [additionalLikes, setLikes] = React.useState<number>(0);
  const [additionalShares, setShares] = React.useState<number>(0);
  return (
    <div className='reaction-container noselect'>
      <div
        onClick={() => {
          setLikes(additionalLikes + 1);
          onLike && onLike();
        }}
        className='likes-container reaction-wrapper'
      >
        <Heart />
        <span>点赞</span>
        <span>{likes + additionalLikes}</span>
      </div>
      <div
        onClick={() => {
          setShares(additionalShares + 1);
          onShare && onShare();
        }}
        className='shares-container reaction-wrapper'
      >
        <Share />
        <span>分享</span>
        <span>{shares + additionalShares}</span>
      </div>
    </div>
  );
}
